module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="ngeo-swipe-line-draggable">\n  <button class="ngeo-swipe-close btn prime btn-sm" ng-click="$ctrl.deactivate()">\n    <i class="fas fa-times"></i>\n  </button>\n  <div class="ngeo-swipe-line"></div>\n  <div class="ngeo-swipe-arrow">' +
((__t = (require('ngeo/icons/swipe.svg?viewbox&height=1em'))) == null ? '' : __t) +
'</div>\n</div>\n';

}
return __p
}